<template>
  <div class="login_container">
    <div :class="clientWidth == 'xs' ? 'login-box login-box-xs' : 'login-box'">
      <!-- 头像区域 -->
      <div class="avatar_box avatar_box-xs">
        <img src="../assets/logo.png" alt />
      </div>
      <!-- 登录表单区域 -->
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        :rules="loginFormRules"
        label-width="0px"
        class="login_form"
      >
        <!-- 用户名 -->
        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            placeholder="Agency ID"
          ></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            v-model="loginForm.password"
            placeholder="Passward"
            type="password"
          ></el-input>
        </el-form-item>
        <!-- 按钮区域 -->
        <el-form-item class="btns">
          <el-button type="primary" @click="login" :loading="loading">{{
            langData.login
          }}</el-button>
          <el-button type="info" @click="resetLoginForm">{{
            langData.reset
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Md5 from 'md5'
import deepClone from '@/utils/deepClone.js'
import { login } from '@/api/login.js'
import { loginLang } from '@/utils/lang.js'
import { bootstrapResponsive } from '@/utils/bootstrapResponsive.js'

export default {
  data() {
    return {
      langData: loginLang[0],
      // 这是登录表单的数据绑定对象
      loginForm: {
        username: '',
        password: ''
      },
      // 这是表单的验证规则对象
      loginFormRules: {
        // 验证用户名是否合法
        username: [
          {
            required: true,
            message: 'Incorrect account',
            trigger: 'blur'
          }
        ],
        // 验证密码是否合法
        password: [
          {
            required: true,
            message: 'Incorrect password',
            trigger: 'blur'
          }
        ]
      },
      // 加载
      loading: false,
      lang: 0,
      clientWidth: 'xs'
    }
  },
  created() {
    this.clientWidth = bootstrapResponsive()
  },
  methods: {
    // 点击重置按钮，重置登录表单
    resetLoginForm() {
      this.$refs.loginFormRef.resetFields()
    },
    login() {
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        this.loading = true
        // Md5 加密
        let date = new Date()
        let curTime = parseInt((date.getTime() / 1000).toString())
        let loginData = deepClone(this.loginForm)
        // loginData.password = curTime + loginData.password
        const { data: res } = await login(
          loginData.username,
          Md5(curTime + Md5(loginData.password)),
          curTime
        )
        this.loading = false

        if (res.code == 0) {
          this.$message({
            message: 'login success',
            center: true,
            type: 'success'
          })
          window.sessionStorage.setItem('loginData', JSON.stringify(res.data))
          this.$router.push('/home')
        } else {
          this.$message({
            message: res.msg,
            center: true,
            type: 'error'
          })
        }
      })
    }
    // // 语言切换
    // switchLang() {
    //   window.sessionStorage.setItem('lang', this.lang)
    // }
  }
}
</script>

<style lang="scss" scoped>
.login_container {
  background-color: #2b4b6b;
  height: 100%;
}

.login-box {
  width: 450px;
  height: 300px;
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .avatar_box {
    height: 130px;
    width: 130px;
    border: 1px solid #eee;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px #ddd;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: #eee;
    }
  }
}

.login-box-xs {
  width: 95vw;
  height: 300px;

  .avatar_box-xs {
    height: 100px;
    width: 100px;
  }
}
.el-row {
  padding-top: 15px;
}
.el-select {
  width: 100px;
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.btns {
  display: flex;
  justify-content: flex-end;
}
</style>
