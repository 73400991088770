// 深拷贝
function deepClone(arr) {
    var obj = arr.constructor == Array ? [] : {}; //第二种方法 var obj=arr instanceof Array?[]:{} //第三种方法 var obj=Array.isArray(arr)?[]:{}
    for (var item in arr) {
      if (typeof arr[item] === "object") {
        obj[item] = deepClone(arr[item]);
      } else {
        obj[item] = arr[item];
      }
    }
    return obj;
  }

export default deepClone